import Vue from "vue";
import VueRouter from "vue-router";
import beforeEach from "./beforeEach";
import arenaRoutes from './routes/arena.js'
import leagueRoutes from './routes/league.js'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/auth",
    name: "Auth",
    meta: { preventAuth: true },
    component: () => import("../views/auth/Auth.vue"),
  },
  {
    path: "/home",
    name: "Home",
    meta: { requiredAuth: true },
    component: () => import("../views/home/Home.vue"),
  },
  ...arenaRoutes,
  ...leagueRoutes,
  {
    path: "*",
    redirect: "/home",
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(beforeEach);

export default router;
