import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import arena from "@/services/arena";
import league from "@/services/league";

Vue.use(Vuex);

const vuexLocal = new VuexPersist({
  key: "localStorage",
  storage: window.localStorage, // persistent on localstorage
  modules: ["darkMode", "token", "service", "viewType"], // persistent variable on state
});

export default new Vuex.Store({
  state: {
    darkMode: true,
    token: null,
    user: null,
    service: "",
    viewType: "grid",
  },

  getters: {
    darkMode: ({ darkMode }) => darkMode,
    authenticated: ({ token, user }) => !!token && !!user,
    token: ({ token }) => token,
    user: ({ user }) => user,
    service: ({ service }) => service,
    viewType: ({ viewType }) => viewType,
  },

  mutations: {
    SET_DARK_MODE(state, payload) {
      state.darkMode = payload;
    },

    SET_TOKEN(state, payload) {
      state.token = payload;
    },

    SET_USER(state, payload) {
      state.user = payload;
    },

    SET_SERVICE(state, payload) {
      state.service = payload;
    },

    SET_VIEW_TYPE(state, payload) {
      state.viewType = payload;
    },
  },

  actions: {
    setDarkMode({ commit }, payload) {
      commit("SET_DARK_MODE", payload);
    },

    setSignIn({ dispatch }, payload) {
      dispatch("setToken", payload.token);
      dispatch("setUser", payload.user);
    },

    setSignOut({ dispatch }) {
      dispatch("setToken", null);
      dispatch("setUser", null);
    },

    setToken({ commit }, payload) {
      commit("SET_TOKEN", payload);

      // set header authotization
      arena.defaults.headers.common["Authorization"] = payload;
      league.defaults.headers.common["Authorization"] = payload;
    },

    setUser({ commit }, payload) {
      commit("SET_USER", payload);
    },

    setService({ commit }, payload) {
      commit("SET_SERVICE", payload);
    },

    setViewType({ commit }, payload) {
      commit("SET_VIEW_TYPE", payload);
    },
  },

  plugins: [vuexLocal.plugin],

  modules: {},
});
