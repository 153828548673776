const moment = require("moment");

export function displayAlert(message, type = 0, fixed = false) {
  this.$root.$emit("display-alert", {
    message: message,
    type: type,
    fixed: fixed,
  });
}

// format digits of decimal point
export function formatAmount(value, decimal = 2) {
  const og = Math.pow(10, decimal);

  value = Math.floor(value * og) / og;

  return Number(value.toFixed(decimal));
}

// add currency mask formatting
export function currencyMask(value, min = 2, max = 2) {
  return Number(value).toLocaleString("pt-BR", {
    minimumFractionDigits: min,
    maximumFractionDigits: max,
  });
}

// remove money mask
export function parseNumber(value) {
  return Number(value.replace(/\./g, "").replace(/,/g, "."));
}

// capitalize all words
export function capitalizeAll(value) {
  const values = value.split(" ");

  return values
    .map((e) => capitalize(e))
    .toString()
    .replaceAll(",", " ");
}

// capitalize first word
export function capitalize(value) {
  return (
    value.toString().charAt(0).toUpperCase() +
    value.toString().substring(1).toLowerCase()
  );
}

export function formatDate(value, format = "DD/MM/YYYY hh:mm") {
  if (!value) return "";

  return moment(value).format(format);
}

// allow only input event key numbers
export function isNumber(event) {
  event = event ? event : window.event;
  let charCode = event.which ? event.which : event.keyCode;

  if (charCode < 48 || charCode > 57) return event.preventDefault();
  else true;
}

// block input event of key numbers
export function blockNumber(event) {
  event = event ? event : window.event;
  let charCode = event.which ? event.which : event.keyCode;

  if (charCode < 48 || charCode > 57) return true;
  else event.preventDefault();
}
