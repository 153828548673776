<template>
  <div class="fill-width">
    <div v-if="!!label" class="text-body-2 mb-1">{{ label }}</div>

    <v-text-field
      v-model="value"
      v-on="$listeners"
      v-mask="mask"
      class="rounded-lg"
      background-color="accent"
      :placeholder="placeholder"
      :rules="required ? rules : []"
      :readonly="readonly"
      :disabled="disabled"
      solo
      flat
    />
  </div>
</template>

<script>
import { mask } from "vue-the-mask";

export default {
  data() {
    return {
      value: "",
    };
  },

  props: {
    currentValue: null,

    mask: null,

    label: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    customRule: {
      type: Array,
      default: () => [],
    },

    required: {
      type: Boolean,
      default: true,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  directives: {
    mask,
  },

  beforeMount() {
    if (!!this.currentValue) this.value = this.currentValue;
  },

  computed: {
    rules() {
      return this.customRule.length <= 0
        ? [(v) => !!v || "Campo Obrigatório"]
        : this.customRule;
    },
  },

  methods: {
    handleInput(event) {
      this.value = event;
      this.$emit("input", event);
    },
  },
};
</script>

<style></style>
