<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="100%"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" class="d-flex align-center">
        <custom-mask-input
          ref="dateInput"
          :mask="'##/##/####'"
          :label="label"
          :placeholder="placeholder"
          :disabled="disabled"
          :readonly="true"
        />

        <v-btn class="mt-1 ml-2 rounded-lg" icon>
          <v-icon>mdi-calendar</v-icon>
        </v-btn>
      </div>
    </template>

    <v-date-picker
      v-model="value"
      color="primary"
      no-title
      @input="handleInputData($event)"
    />
  </v-menu>
</template>

<script>
const moment = require("moment");

export default {
  data() {
    return {
      menu: false,
      value: "",
    };
  },

  props: {
    currentValue: null,

    label: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "##/##/####",
    },

    customRule: {
      type: Array,
      default: () => [],
    },

    required: {
      type: Boolean,
      default: true,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  beforeMount() {
    if (!!this.currentValue) {
      this.handleInputData(this.currentValue);
    }
  },

  methods: {
    handleInputData(event) {
      if (event) {
        this.$refs.dateInput.handleInput(
          moment(event, "YYYY-MM-DD").format("DD/MM/YYYY")
        );
        this.value = event;
        this.menu = false;

        this.$emit("input", event);
      }
    },

    handleInput(event) {
      this.handleInputData(event);
      this.$emit("input", event);
    },
  },
};
</script>

<style>
.fill-width {
  width: 100%;
}
</style>
