<template>
  <div class="d-flex flex-column fill-height">
    <!-- app bar -->
    <v-app-bar id="nav" color="background" flat dense app>
      <v-btn class="rounded-lg ml-2 mr-4" small icon @click="drawer = !drawer">
        <v-icon>mdi-menu</v-icon>
      </v-btn>

      <div class="text-h6">Boas vindas, {{ user.firstName }}</div>

      <v-spacer />

      <ThemeProvider />

      <v-btn class="rounded-lg mx-2" small icon @click="logoff()">
        <v-icon>mdi-location-exit</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      v-if="!!service"
      :mini-variant="$vuetify.breakpoint.lgAndUp"
      color="surface elevation-1"
      floating
      app
    >
      <v-list class="transparent" dense nav>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              v-bind="attrs"
              v-on="on"
              class="mb-4"
              @click="clearService()"
            >
              <v-list-item-icon>
                <v-icon>mdi-sync</v-icon>
              </v-list-item-icon>

              <v-list-item-title> Trocar serviço </v-list-item-title>
            </v-list-item>
          </template>

          <span>Trocar serviço</span>
        </v-tooltip>

        <router-link
          v-for="(item, i) in routes[service]"
          v-slot="{ href, navigate, isActive }"
          :to="'/' + service + item.path"
          :key="i"
          custom
        >
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item
                v-bind="attrs"
                v-on="on"
                class="mb-4 px-0"
                :href="href"
                @click="navigate"
                style="max-height: 40px"
              >
                <v-list-item-avatar
                  :color="isActive ? 'primary' : 'accent'"
                  size="40"
                  rounded
                >
                  <v-icon :dark="isActive">{{ item.icon }}</v-icon>
                </v-list-item-avatar>

                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item>
            </template>

            <span>{{ item.name }}</span>
          </v-tooltip>
        </router-link>
      </v-list>
    </v-navigation-drawer>

    <!-- slot view -->
    <v-main class="background">
      <slot />
    </v-main>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ThemeProvider from "@/components/app/ThemeProvider";

export default {
  data() {
    return {
      drawer: true,
    };
  },

  components: {
    ThemeProvider,
  },

  computed: {
    ...mapState(["user", "service"]),

    routes() {
      return {
        arena: [
          {
            name: "Ínicio",
            path: "/main",
            icon: "mdi-home",
          },
          {
            name: "Usuários",
            path: "/users",
            icon: "mdi-account",
          },
        ],
        league: [
          {
            name: "Ínicio",
            path: "/main",
            icon: "mdi-home",
          },
          {
            name: "Jogadores",
            path: "/players",
            icon: "mdi-account",
          },
          {
            name: "Jogos",
            path: "/games",
            icon: "mdi-controller-classic",
          },
          {
            name: "Ligas",
            path: "/leagues",
            icon: "mdi-trophy",
          },
          {
            name: "Equipes",
            path: "/teams",
            icon: "mdi-shield-sword",
          },
          {
            name: "Escolas",
            path: "/schools",
            icon: "mdi-view-list",
          },
          {
            name: "Notícias",
            path: "/news",
            icon: "mdi-newspaper-variant",
          },
        ],
      };
    },
  },

  methods: {
    clearService() {
      this.$router.push({ path: "/home" });
    },

    logoff() {
      this.$root.$emit("logoff");
    },
  },
};
</script>

<style></style>
