export default [
  {
    path: "/arena",
    redirect: "/arena/main",
    meta: { requiredAuth: true },
    component: () => import("@/views/arena/Arena.vue"),
    children: [
      {
        path: "main",
        name: "Arena",
        meta: { requiredAuth: true },
        component: () => import("@/views/arena/children/main/Main.vue"),
      },
      {
        path: "users",
        name: "Arena Users",
        meta: { requiredAuth: true },
        component: () => import("@/views/arena/children/users/Users.vue"),
      },
    ],
  },
];
