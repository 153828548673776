<template>
  <div class="fill-width">
    <div v-if="!!label" class="text-body-2 mb-1">{{ label }}</div>

    <v-file-input
      v-model="value"
      v-on="$listeners"
      background-color="accent"
      prepend-icon=""
      append-icon="mdi-paperclip"
      :class="`${$vuetify.theme.dark ? 'dark' : 'light'} rounded-lg`"
      :accept="accept"
      :placeholder="placeholder"
      :rules="required ? rules : []"
      :readonly="readonly"
      :disabled="disabled"
      chips
      solo
      flat
      @change="handleInput($event)"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: null,
    };
  },

  props: {
    currentValue: null,

    label: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    accept: {
      type: String,
      default: "image/*",
    },

    customRule: {
      type: Array,
      default: () => [],
    },

    required: {
      type: Boolean,
      default: true,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  beforeMount() {
    if (!!this.currentValue) this.value = this.currentValue;
  },

  computed: {
    rules() {
      return this.customRule.length <= 0
        ? [(v) => !!v || "Campo Obrigatório"]
        : this.customRule;
    },
  },

  methods: {
    handleInput(event) {
      this.value = event;
      this.$emit("input", event);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .light .v-file-input__text--placeholder {
  color: #bbb9bf !important;
}

::v-deep .dark .v-file-input__text--placeholder {
  color: #bbb9bf65 !important;
}
</style>
