import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@babel/polyfill";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";

import LoaderHover from "./components/loaders/LoaderHover";
import LoaderView from "./components/loaders/LoaderView";
import LoaderContent from "./components/loaders/LoaderContent";

import HeaderView from "./components/content/HeaderView";
import CustomTable from "./components/content/CustomTable";
import CustomDialog from "./components/content/CustomDialog";
import CardGrid from "./components/content/CardGrid";
import SwitchShowType from "./components/content/SwitchShowType";

import CustomInput from "./components/form/CustomInput";
import CustomMaskInput from "./components/form/CustomMaskInput";
import CustomFileInput from "./components/form/CustomFileInput";
import CustomSelect from "./components/form/CustomSelect";
import CustomTextArea from "./components/form/CustomTextArea";
import CustomDatePick from "./components/form/CustomDatePick";
import CustomAutoComplete from "./components/form/CustomAutoComplete";
import AvatarPick from "./components/form/AvatarPick";

import FilterInput from "./components/filters/FilterInput";

Vue.component("loader-hover", LoaderHover);
Vue.component("loader-view", LoaderView);
Vue.component("loader-content", LoaderContent);

Vue.component("header-view", HeaderView);
Vue.component("custom-table", CustomTable);
Vue.component("custom-dialog", CustomDialog);
Vue.component("card-grid", CardGrid);
Vue.component("switch-show-type", SwitchShowType);

Vue.component("custom-input", CustomInput);
Vue.component("custom-mask-input", CustomMaskInput);
Vue.component("custom-file-input", CustomFileInput);
Vue.component("custom-select", CustomSelect);
Vue.component("custom-text-area", CustomTextArea);
Vue.component("custom-date-pick", CustomDatePick);
Vue.component("custom-auto-complete", CustomAutoComplete);
Vue.component("avatar-pick", AvatarPick);

Vue.component("filter-input", FilterInput);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
