<template>
  <v-app id="app">
    <SplashScreen v-if="loading" />

    <Application v-else-if="$store.getters.authenticated">
      <router-view />
    </Application>

    <v-main v-else>
      <router-view />
    </v-main>

    <SnackBar />
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import { loadSession } from "./services/arena/auth.js";
import Application from "./template/Application.vue";
import SplashScreen from "./template/SplashScreen.vue";
import SnackBar from "./components/app/SnackBar.vue";

export default {
  name: "App",

  data() {
    return {
      loading: true,
    };
  },

  components: {
    Application,
    SplashScreen,
    SnackBar,
  },

  created() {
    this.startSession();
  },

  beforeMount() {
    this.startApplication();

    this.$root.$on("logoff", () => {
      this.logoff();
    });
  },

  beforeDestroy() {
    this.$root.$off("logoff");
  },

  methods: {
    ...mapActions(["setToken", "setSignIn", "setSignOut"]),

    // checks if the token is still valid for a session
    async startSession() {
      const token = this.$store.getters.token;

      if (token) {
        this.setToken(token);

        try {
          await loadSession().then((res) => {
            this.setSignIn({
              user: res.body.user,
              token: res.body.token,
            });
          });
        } catch {
          this.logoff();
        }
      }

      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },

    // start theme
    async startApplication() {
      this.$vuetify.theme.dark = this.$store.getters.darkMode;
    },

    async logoff() {
      this.loading = true;
      await this.setSignOut();
      await this.$router.push({ path: "/auth" });
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fill-width {
  width: 100%;
}

.pointer {
  cursor: pointer;
}
</style>
