export default [
  {
    path: "/league",
    redirect: "/league/main",
    meta: { requiredAuth: true },
    component: () => import("@/views/league/League.vue"),
    children: [
      {
        path: "main",
        name: "League",
        meta: { requiredAuth: true },
        component: () => import("@/views/league/children/main/Main.vue"),
      },
      {
        path: "players",
        name: "Players",
        meta: { requiredAuth: true },
        component: () => import("@/views/league/children/players/Players.vue"),
      },
      {
        path: "player-details/:id",
        meta: { requiredAuth: true },
        component: () =>
          import("@/views/league/children/playerDetails/PlayerDetails.vue"),
        children: [
          {
            path: "",
            name: "Player Details",
            meta: { requiredAuth: true },
            component: () =>
              import(
                "@/views/league/children/playerDetails/children/Details.vue"
              ),
          },
          {
            path: "teams",
            name: "Player Teams",
            meta: { requiredAuth: true },
            component: () =>
              import(
                "@/views/league/children/playerDetails/children/Teams.vue"
              ),
          },
        ],
      },
      {
        path: "teams",
        name: "Teams",
        meta: { requiredAuth: true },
        component: () => import("@/views/league/children/teams/Teams.vue"),
      },
      {
        path: "team-details/:id",
        name: "Team Details",
        meta: { requiredAuth: true },
        component: () =>
          import("@/views/league/children/teamDetails/TeamDetails.vue"),
      },
      {
        path: "leagues",
        name: "Leagues",
        meta: { requiredAuth: true },
        component: () => import("@/views/league/children/leagues/Leagues.vue"),
      },
      {
        path: "league-details/:id",
        component: () =>
          import("@/views/league/children/leagueDetails/LeagueDetails.vue"),
        children: [
          {
            path: "",
            name: "League Details",
            meta: { requiredAuth: true },
            component: () =>
              import(
                "@/views/league/children/leagueDetails/children/Details.vue"
              ),
          },
          {
            path: "table",
            name: "League Table",
            meta: { requiredAuth: true },
            component: () =>
              import(
                "@/views/league/children/leagueDetails/children/Table.vue"
              ),
          },
          {
            path: "teams",
            name: "League Teams",
            meta: { requiredAuth: true },
            component: () =>
              import(
                "@/views/league/children/leagueDetails/children/Teams.vue"
              ),
          },
          {
            path: "players",
            name: "League Players",
            meta: { requiredAuth: true },
            component: () =>
              import(
                "@/views/league/children/leagueDetails/children/Players.vue"
              ),
          },
        ],
      },
      {
        path: "games",
        name: "Games",
        meta: { requiredAuth: true },
        component: () => import("@/views/league/children/games/Games.vue"),
      },
      {
        path: "schools",
        name: "Schools",
        meta: { requiredAuth: true },
        component: () => import("@/views/league/children/schools/Schools.vue"),
      },
      {
        path: "school-details/:id",
        name: "School Details",
        meta: { requiredAuth: true },
        component: () =>
          import("@/views/league/children/schoolDetails/SchoolDetails.vue"),
      },
      {
        path: "news",
        name: "News",
        meta: { requiredAuth: true },
        component: () => import("@/views/league/children/news/News.vue"),
      },
    ],
  },
];
